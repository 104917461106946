import React from 'react'
import { navigate, Redirect, useParams } from '@reach/router'
import { Typography } from '@material-ui/core'
import { Button } from '@refera/ui-web'

import useStyles from './styles'

/* Assets */
import HandshakeSVG from './assets/rafiki-handshake.svg'
import HeavyboxSVG from './assets/rafiki-heavybox.svg'
import MaintenanceSVG from './assets/rafiki-maintenance.svg'
import Svg from '_/components/svg'

export function ReferaPresentation() {
  const styles = useStyles()
  const { serviceOrderId } = useParams()

  const handleConfirmButtonClick = () =>
    navigate(`/solicitar-manutencao/quinto-andar-b2c?idChamado=${serviceOrderId}`)
  const handleDenyButtonClick = () => navigate(`/reprove-service/${serviceOrderId}`)

  if (!serviceOrderId || Number.isNaN(Number(serviceOrderId)) || Number(serviceOrderId) < 1) {
    return <Redirect to="/nao-encontrado" noThrow />
  }

  return (
    <main className={styles.container}>
      <section className={styles.grid}>
        <div className={styles.gridFirst}>
          <Typography className={`${styles.sectionParagraph} ${styles.sectionParagraphSmaller}`}>
            ESPECIALISTAS EM SERVIÇOS DE MANUTENÇÃO PARA IMÓVEIS
          </Typography>
          <Typography className={styles.sectionTitle}>
            Somos a Refera, uma empresa parceira oficial da Quinto Andar{' '}
            <span role="img" aria-label="blue-heart">
              💙
            </span>
            .
          </Typography>
        </div>
        <div className={styles.gridThird}>
          <Typography className={styles.sectionParagraph}>
            A Refera é a maior plataforma de manutenções do Brasil, realizamos mais de 4 mil
            serviços por mês e atendemos as maiores imobiliárias do país.
          </Typography>
          <div className={styles.buttonContainer}>
            <Button className={styles.button} onClick={handleConfirmButtonClick}>
              Quero pedir um orçamento
            </Button>
            <Button
              variant="secondary"
              color="red"
              className={styles.button}
              onClick={handleDenyButtonClick}
            >
              Não quero um orçamento
            </Button>
          </div>
        </div>
        <div className={styles.gridSecond}>
          <Svg icon={HandshakeSVG} className={styles.svg} />
        </div>
      </section>
      <div className={styles.yellowDiv}>
        <Typography className={styles.yellowDivText}>
          ORÇAMENTO SEM COMPROMISSO EM ATÉ 24 HORAS&nbsp;&nbsp;•&nbsp;&nbsp;ORÇAMENTO SEM
          COMPROMISSO EM ATÉ 24 HORAS&nbsp;&nbsp;•&nbsp;&nbsp;ORÇAMENTO SEM COMPROMISSO EM ATÉ 24
          HORAS&nbsp;&nbsp;•&nbsp;&nbsp;ORÇAMENTO SEM COMPROMISSO EM ATÉ 24 HORAS
        </Typography>
      </div>
      <section className={`${styles.section} ${styles.sectionBlueBackground}`}>
        <div className={styles.sectionContent}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Svg icon={MaintenanceSVG} className={styles.svg} />
          </div>
          <div>
            <Typography className={`${styles.sectionTitle} ${styles.whiteText}`}>
              Olha o que a gente pode fazer por você aqui{' '}
              <span role="img" aria-label="point-down">
                👇🏻
              </span>
            </Typography>
            <Typography className={`${styles.secondSectionParagraph} ${styles.whiteText}`}>
              <span role="img" aria-label="check">
                ✅
              </span>{' '}
              Orçamento completo com material e mão de obra;
              <br />
              <span role="img" aria-label="check">
                ✅
              </span>{' '}
              Garantia total do serviço;
              <br />
              <span role="img" aria-label="check">
                ✅
              </span>{' '}
              Parcelamento em até 10x vezes;
              <br />
              <span role="img" aria-label="check">
                ✅
              </span>{' '}
              Prestadores selecionados pela Refera;
              <br />
              <span role="img" aria-label="check">
                ✅
              </span>{' '}
              E um time de suporte para te ajudar!
            </Typography>
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <div className={`${styles.sectionContent} ${styles.mdColumnReverse}`}>
          <div className={styles.thirdSectionContentContainer}>
            <Typography className={`${styles.sectionTitle} ${styles.sixHundredMarginZero}`}>
              Somos especialistas em serviços de manutenção para imóveis que estão sendo
              desocupados.
            </Typography>
            <Typography className={styles.sectionParagraph}>
              A vantagem de fazer esse serviço com a gente, é que: incluímos material e mão de obra
              no orçamento, fornecemos garantia total do serviço, entregamos nota fiscal, além de{' '}
              <strong>pagamento facilitado em 10x vezes,</strong> e um baita time de suporte para te
              ajudar.
            </Typography>
            <div className={styles.buttonContainer}>
              <Button className={styles.button} onClick={handleConfirmButtonClick}>
                Quero pedir um orçamento
              </Button>
              <Button
                variant="secondary"
                color="red"
                className={styles.button}
                onClick={handleDenyButtonClick}
              >
                Não quero um orçamento
              </Button>
            </div>
          </div>
          <Svg icon={HeavyboxSVG} className={styles.svg} />
        </div>
      </section>
      <footer className={styles.footer}>
        <Typography className={styles.footerText}>
          © 2023 Refera Tecnologia - Desenvolvido por Refera
        </Typography>
      </footer>
    </main>
  )
}
