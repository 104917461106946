import React from 'react'

import { navigate } from '@reach/router'
import { Button } from '@refera/ui-web'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { ROUTES } from '_/utils/constants'

export function PageHeader({ isLoading }) {
  const styles = useStyles()

  const createReferaUserButtonClick = () => {
    navigate(`${ROUTES.REFERA_USERS}/novo`)
  }

  return (
    <HeaderTitle title="Usuários da Refera" backButtonAction={() => navigate('/')}>
      <div className={styles.actionButtons}>
        <Button
          style={{ width: '100px' }}
          onClick={createReferaUserButtonClick}
          disabled={isLoading}
        >
          Novo
        </Button>
      </div>
    </HeaderTitle>
  )
}
