import React, { useCallback, useMemo, useState } from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from '@reach/router'
import { parse } from 'query-string'
import { useToast } from '_/hooks/use-toast'

import { resetPassword, RESET_PASSWORD } from '_modules/authentication/actions'
import { validatePasswordStrength } from '_utils/helpers'
import PasswordField from '_components/password-field'
import useHandleInput from '_hooks/use-handle-input'
import Button from '_components/button'
import Svg, { ICON } from '_components/svg'
import useToggle from '_hooks/use-toggle'
import useFetchCall from '_hooks/use-fetch-call'
import { ROUTES } from '_utils/constants'
import { HEADER_TAGS } from '_utils/header-tags'
import { PasswordRules } from '_/views/agency-user/textfield'

import Header from './header'
import useStyles from './styles'

const RecoverPassword = () => {
  const dispatch = useDispatch()
  const [password, handlePassword] = useHandleInput()
  const [confirmationPassword, handleConfirmationPassword] = useHandleInput()
  const [error, setError] = useState('')
  const [successState, handleState] = useToggle()
  const styles = useStyles({ successState })
  const { uid, token } = useParams()
  const { showToast } = useToast()
  const { state, search } = useLocation()
  const { fromMobile } = parse(search)

  const validatePasswordsOnBlur = () => {
    if (password !== confirmationPassword) {
      setError('A senha digitada em ambos os campos não é a mesma.')
    } else {
      setError('')
    }
  }

  const handleChangePassword = useCallback(() => {
    if (password === confirmationPassword) {
      const isPasswordStrong = validatePasswordStrength(password)
      if (isPasswordStrong) {
        dispatch(resetPassword({ newPassword1: password, newPassword2: password, token, uid }))
      } else {
        showToast({ message: 'A senha não atende os requisitos mínimos.', type: 'error' })
      }
    }
  }, [dispatch, password, confirmationPassword, token, uid])

  const [isLoading] = useFetchCall(RESET_PASSWORD.ACTION, handleState)

  // Mensagem que será exibida abaixo do título
  const infoMessage = useMemo(() => {
    return state?.redirected || !!fromMobile
      ? 'Por questões de segurança, é necessário que você troque sua senha. Qualquer dúvida, entre em contato com a Refera'
      : `A gente veio aqui te ajudar a trocar sua senha :)`
  }, [])

  return (
    <>
      {HEADER_TAGS[ROUTES.RECOVER_PASSWORD].header}
      <Grid className={styles.container}>
        <Header />
        <Paper className={styles.paper}>
          {successState ? (
            <>
              <Grid className={styles.key}>
                <CheckIcon className={styles.check} />
              </Grid>
              <Typography align="center" color="primary" variant="h3" component="h1">
                Senha alterada com sucesso
              </Typography>
              <Typography align="center" className={styles.info} variant="body2">
                Utilize esta senha para realizar o login no aplicativo da próxima vez.
              </Typography>
            </>
          ) : (
            <>
              <Grid className={styles.key}>
                <Svg className={styles.keyIcon} type={ICON.KEY} />
              </Grid>
              <Typography align="center" color="primary" variant="h3" component="h1">
                Crie uma nova senha
              </Typography>
              <Typography align="center" className={styles.info} variant="body2">
                {infoMessage}
              </Typography>
              <PasswordField
                fullWidth
                label="Digite uma nova senha"
                value={password}
                onChange={handlePassword}
                error={!!error.length}
                disabled={isLoading}
                helperText={error}
                onBlur={validatePasswordsOnBlur}
              />
              <PasswordRules />
              <PasswordField
                fullWidth
                label="Confirmar senha"
                value={confirmationPassword}
                onChange={handleConfirmationPassword}
                disabled={isLoading}
                error={!!error.length}
                helperText={error}
                onBlur={validatePasswordsOnBlur}
              />
              <Button
                onClick={handleChangePassword}
                className={styles.button}
                color="primary"
                fullWidth
                variant="contained"
                isLoading={isLoading}
              >
                Redefinir senha
              </Button>
            </>
          )}
        </Paper>
        <Grid component="footer" className={styles.footer}>
          <Typography variant="body2" component="p" className={styles.footerContent}>
            Todos os direitos reservados - Refera Tecnologia S.A.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default RecoverPassword
