/* eslint-disable */
import React, { useMemo, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'

function DashboardReport() {
  const userToken = useSelector(x => x.user.get('authToken'))

  const reportUrl = useMemo(
    () =>
      `${process.env.REACT_APP_URL_DATASTUDIO}?params=${encodeURI(
        JSON.stringify({ token: userToken })
      )}`,
    [userToken]
  )

  const renderContent = useMemo(
    () => (
      <div
        style={{
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          height: '100vh',
          width: '100%',
          position: 'relative',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={reportUrl}
          frameBorder="0"
          style={{
            border: 0,
            width: '100%',
            height: '100%',
          }}
          allowFullScreen
        />
      </div>
    ),
    [reportUrl]
  )

  return <div>{renderContent}</div>
}

export default DashboardReport
