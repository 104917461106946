import React, { useCallback, useEffect, useState } from 'react'

import useStyles from './styles'
import { PageHeader } from './PageHeader'
import { Datagrid } from '@refera/ui-web'
import { Grid } from '@mui/material'
import { ReferaUsersColumns } from './constants'
import { TABLE_SX } from '_/views/finance/utils/constants'
import { useDispatch } from 'react-redux'
import { listReferaUsers, activateReferaUserEmail } from '_/modules/user/actions'
import humps from 'humps'
import { navigate } from '@reach/router'
import { ROUTES } from '_/utils/constants'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'

export default function ListReferaUsers() {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showDialog, closeDialog } = useDialog()
  const [isLoading, setIsLoading] = useState(true)
  const [referaUsers, setReferaUsers] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [orderBy, setOrderBy] = useState('name')
  const { showToast } = useToast()

  const handleOrderBy = useCallback(orderObj => {
    const order = orderObj[0]
    if (!order) {
      handleOrderBy('name')
      return
    }
    const { field, sort } = order
    const decamelizedField = humps.decamelize(field)
    sort === 'desc' ? setOrderBy(`-${decamelizedField}`) : setOrderBy(decamelizedField)
  }, [])

  const handleCellClick = useCallback(({ field, row }) => {
    if (field === 'actions') return
    navigate(`${ROUTES.REFERA_USERS}/${row.id}`)
  }, [])

  const sendEmailCallback = (event, row) => {
    showDialog({
      subject: 'Você tem certeza que deseja executar esta ação?',
      labelApprove: 'Sim',
      labelCancel: 'Não',
      onApprove: () => {
        dispatch(activateReferaUserEmail(row.id))
          .then(() => {
            closeDialog()
            showToast({ type: 'success' })
          })
          .catch(() => {
            closeDialog()
            showToast({ type: 'error' })
          })
      },
      onCancel: closeDialog,
    })
  }

  useEffect(() => {
    dispatch(listReferaUsers({ page: page + 1, pageSize, orderBy }))
      .then(data => {
        setReferaUsers(data?.results || [])
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }, [page, pageSize, orderBy])

  return (
    <>
      <main className={styles.container}>
        <PageHeader isLoading={isLoading} />

        <Grid className={styles.tableContainer}>
          <Grid className={styles.mainTable}>
            <Datagrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              onSortModelChange={handleOrderBy}
              loading={isLoading}
              rows={referaUsers}
              columns={ReferaUsersColumns(sendEmailCallback)}
              paginationMode="server"
              onPageChange={setPage}
              page={page}
              onPageSizeChange={setPageSize}
              pageSize={pageSize}
              rowCount={referaUsers.length || 0}
              sx={TABLE_SX}
              noRowsLabel="Nenhum resultado encontrado"
              onCellClick={handleCellClick}
            />
          </Grid>
        </Grid>
      </main>
    </>
  )
}
