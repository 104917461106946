import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    height: 'calc(100vh - 48px)',
    paddingBottom: spacing(32),
  },
  actionButtons: {
    display: 'flex',
    gap: spacing(8),
  },
  content: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 75px)',
    overflowY: 'auto',
    paddingLeft: '6rem',
  },
  form: {
    width: '100%',
    flex: 1,
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8vh',
    gap: spacing(16),
  },
  label: {
    color: palette.gray[64],
    fontSize: spacing(12),
  },
  maskInput: {
    '& .MuiInputBase-root': {
      height: `33px !important`,
    },
  },
  box: {
    display: 'flex',
    gap: spacing(50),
    width: '100%',
  },
}))
